<template>
  <div>
    <paginate
      :paginate="pagination"
      @change="paginate"
      :showOptions="true"
      :options="paginateOptions"
    />
    <table>
      <thead>
        <tr>
          <th>Nom</th>
          <th>Note moyenne</th>
          <th>Évaluations</th>
          <th>Sécurité</th>
          <th>Achat</th>
          <th>Projets</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td class="loader-container" colspan="6">
            <i class="loader"></i>
          </td>
        </tr>
        <tr v-else-if="companies.length === 0">
          <td colspan="6">No data available</td>
        </tr>
        <template v-for="(company, index) in companies">
          <tr
            :key="company.id"
            @click="toggleAgencies(index)"
            :class="{ selected: expandedIndex === index }"
            class="selectable"
          >
            <td>{{ company.name }}</td>
            <td>
              <div style="display: flex">
                <span
                  class="pastille"
                  :class="{
                    red: company.average.score < 3.33,
                    yellow:
                      company.average.score >= 3.33 &&
                      company.average.score < 6.66,
                    green: company.average.score >= 6.66,
                  }"
                ></span>
                <span style="min-width: 20px">{{
                  company.average.score.toFixed(1)
                }}</span>
              </div>
            </td>
            <td>{{ company.average.count }}</td>
            <td>{{ company.details.safety.score.toFixed(1) }}</td>
            <td>{{ company.details.procurement.score.toFixed(1) }}</td>
            <td>{{ company.details.projects.score.toFixed(1) }}</td>
          </tr>
          <template v-if="expandedIndex === index">
            <tr
              v-for="agency in agencies"
              :key="company.id + '-' + agency.id"
              class="agencies"
            >
              <td style="padding-left: 30px">{{ agency.name }}</td>
              <td>
                <div style="display: flex">
                  <span
                    class="pastille"
                    :class="{
                      red: agency.average.score < 3.33,
                      yellow:
                        agency.average.score >= 3.33 &&
                        agency.average.score < 6.66,
                      green: agency.average.score >= 6.66,
                    }"
                  ></span>
                  <span style="min-width: 20px">{{
                    agency.average.score.toFixed(1)
                  }}</span>
                </div>
              </td>
              <td>{{ agency.average.count }}</td>
              <td>{{ agency.details.safety.score.toFixed(1) }}</td>
              <td>{{ agency.details.procurement.score.toFixed(1) }}</td>
              <td>{{ agency.details.projects.score.toFixed(1) }}</td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import Paginate from "@/components/Paginate";
import PaginationBuilder from "@/lib/Paginate";
import axios from "axios";

export default {
  name: "EvaluationTable",
  components: {
    Paginate,
  },
  props: {
    fetch: Function,
    singleSelect: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 10,
    },
    paginateOptions: {
      type: Array,
      default: () => [50, 100, 200],
    },
    identifier: {
      type: String,
      default: "id",
    },
    order: {
      type: String,
      default: "ASC",
    },
    paramsFilters: {
      type: String,
      default: "start_date=&end_datethis.end_date&companies=&agencies=",
    },
  },
  data() {
    return {
      loading: false,
      internalColumns: [],
      hasFilters: false,
      companies: [],
      agencies: [],
      start_date: "2000-01-01",
      end_date: new Date().toISOString().slice(0, 10),
      pagination: {
        page: 1,
        total: 0,
        size: this.size,
        sort: {
          column: this.identifier,
          direction: this.order,
        },
      },
      selectAll: false,
      selected: [],
      API: axios.defaults.baseURL,
      authToken: this.$store.getters["auth/getToken"],
      emptyTableMessage: this.customEmptyTableMessage,
      tableSummary: null,
      expandedIndex: null,
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.pagination.sort.column = this.identifier;
      this.pagination.sort.direction = this.order;
      this.refreshWithFilters(this.paramsFilters);
    },

    refreshWithFilters(paramsFilters) {
      const p = new PaginationBuilder(this.preparePaginationObject());
      this.loading = true;
      this.fetch(p, paramsFilters)
        .then((data) => {
          this.callback(data);
        })
        .catch(this.fetchError)
        .finally(() => {
          this.$nextTick(() => {
            this.loading = false;
          });
        });
    },
    callback(data) {
      this.pagination.total = data.totalElements;
      this.companies = Array.isArray(data.content) ? data.content : [];
      this.emptyTableMessage = this.$t("expressions.noData");
      this.tableSummary = data.summary || null;
    },
    preparePaginationObject() {
      return {
        ...this.pagination,
        page: this.pagination.page - 1,
        sort: {
          ...this.pagination.sort,
        },
        filters: [],
      };
    },
    paginate(p) {
      this.pagination = p;
      this.expandedIndex = null;
      this.refreshWithFilters(this.paramsFilters);
    },
    toggleAgencies(index) {
      if (this.expandedIndex === index) {
        this.expandedIndex = null;
        this.agencies = [];
      } else {
        this.expandedIndex = index;
        this.fetchAgenciesEvaluations(this.companies[index].id);
      }
    },
    fetchAgenciesEvaluations(companyId) {
      this.loading = true;
      this.$api
        .fetchAgenciesEvaluations(companyId, this.paramsFilters)
        .then((response) => {
          this.agencies = response;
        })
        .catch((error) => {
          console.error("Error fetching agency evaluations:", error);
          this.agencies = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

thead {
  background-color: #f4f4f4;
}

th,
td {
  padding: 10px;
  text-align: left;
}

tr {
  border-bottom: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr.selected {
  background-color: rgb(1, 159, 196);
  border: solid 1px rgb(1, 159, 196);
  color: white;
}

tr.agencies {
  border-left: solid 1px rgb(1, 159, 196);
  border-right: solid 1px rgb(1, 159, 196);
  color: #787878;
}

tr.agencies:last-child {
  border-bottom: solid 1px rgb(1, 159, 196);
}

.selectable {
  cursor: pointer;
}

.pastille {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 20px;
}

.red {
  background-color: #db3912;
}
.yellow {
  background-color: #fe9a01;
}
.green {
  background-color: #0e961b;
}

.loader-container {
  font-size: 30px;
  text-align: center;
  background-color: #fff;
  .loader {
    margin: 0 auto;
  }
}
</style>
